import type { NextPage } from 'next';
import { Col, Row } from 'react-bootstrap';
import LoginForm from '../components/LoginForm';
import { useAuthContext } from '../contexts/auth';
import usePageRedirector from '../hooks/usePageRedirector';

const Home: NextPage = () => {
	const authContext = useAuthContext();
	usePageRedirector();

	let AuthButton;
	if (authContext.authUser) {
		AuthButton = <div>Loading...</div>;
	} else if (!authContext.loading) {
		AuthButton = (
			<>
				<LoginForm />
			</>
		);
	}

	return (
		<div>
			<Row style={{ minHeight: '600px' }}>
				<Col className={'m-auto text-center justify-content-center'}>
					<Row>
						<Col></Col>
					</Row>
					<Row style={{ minHeight: '200px' }}>
						<Col className="align-self-center">
							<div>{AuthButton}</div>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default Home;
