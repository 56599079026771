import { useRouter } from 'next/router';
import useApplicationStatus, { ApplicationStatus } from './useApplicationStatus';

enum Routes {
	HOME = '/',
	APPLY = '/apply',
	APPLICATION_STATUS = '/status',
}

const usePageRedirector = () => {
	const applicationStatus = useApplicationStatus();
	const router = useRouter();

	const pushIfNeeded = (destination: Routes) => {
		if (router.pathname !== destination) {
			router.push(destination);
		}
	};

	switch (applicationStatus) {
		case ApplicationStatus.NOT_YET_APPLIED:
			pushIfNeeded(Routes.APPLY);
			break;
		case ApplicationStatus.APPLIED:
		case ApplicationStatus.WAITLISTED:
		case ApplicationStatus.ACCEPTED:
			pushIfNeeded(Routes.APPLICATION_STATUS);
			break;
	}
};

export default usePageRedirector;
