import { Loader } from '@mantine/core';
import { ChangeEvent, FunctionComponent } from 'react';
import { Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input/mobile';
import { useAnalytics } from '../../contexts/analytics';
import { LoginFormParams, LoginFormStage } from './types';

const SubmitPhoneNumberForm: FunctionComponent<
	Pick<LoginFormParams, 'phoneNumberForm' | 'submitPhoneNumber' | 'errorMessage'>
> = ({ phoneNumberForm, submitPhoneNumber, errorMessage }) => {
	const analytics = useAnalytics();
	return (
		<Col>
			<Row>
				<Col className="text-center">
					<h1>Enter your number to apply</h1>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form
						onSubmit={phoneNumberForm.onSubmit((v) => {
							analytics.sendButtonPressEvent('phone_number_submit');
							submitPhoneNumber(v.phoneNumber);
						})}
					>
						<Row className="my-3">
							<FormGroup as={Col} className="w-100">
								<PhoneInput
									autoFocus
									defaultCountry="US"
									autoComplete="tel-national"
									{...phoneNumberForm.getInputProps('phoneNumber')}
								/>
								<p className="text-danger">
									{phoneNumberForm.getInputProps('phoneNumber').error || errorMessage}
								</p>
							</FormGroup>
						</Row>
						<Row className="my-3">
							<FormGroup as={Col}>
								<button
									className="apply-btn"
									type="submit"
									disabled={!isPossiblePhoneNumber(phoneNumberForm.values.phoneNumber || '')}
								>
									Continue
								</button>
								<div className="text-center mt-2">
									<p style={{ opacity: 0.45, fontSize: '12px' }}>3 minutes tops</p>
								</div>
							</FormGroup>
						</Row>
					</Form>
				</Col>
			</Row>

			<p className="mt-0 mb-0" style={{ fontSize: '8pt' }}>
				Msg & data rates may apply
			</p>
		</Col>
	);
};

const SubmitVerificationCodeForm: FunctionComponent<
	Pick<
		LoginFormParams,
		'verificationCodeForm' | 'errorMessage' | 'submitVerificationCode' | 'backToPhoneNumber'
	> & {
		phoneNumber: string;
	}
> = ({
	verificationCodeForm,
	errorMessage,
	phoneNumber,
	backToPhoneNumber,
	submitVerificationCode,
}) => {
	const analytics = useAnalytics();

	return (
		<Col>
			<Row>
				<Col className="text-center mb-3">
					<h4 className="fw-bold">Enter your code</h4>
				</Col>
			</Row>
			<Row className="text-left">
				<Col>
					<p>Enter your code sent to {phoneNumber}</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<Form
						onSubmit={verificationCodeForm.onSubmit((v) => {
							analytics.sendButtonPressEvent('verification_code_submit');
							submitVerificationCode(v.verificationCode);
						})}
					>
						<Row className="mb-3">
							<FormGroup as={Col}>
								<FormControl
									autoFocus
									type="text"
									autoComplete="one-time-code"
									inputMode="numeric"
									{...verificationCodeForm.getInputProps('verificationCode')}
									onChange={(event: ChangeEvent<HTMLInputElement>) =>
										verificationCodeForm.setFieldValue(
											'verificationCode',
											event.currentTarget.value.replaceAll(/\D/g, '')
										)
									}
								/>
								<p className="text-danger">
									{verificationCodeForm.getInputProps('verificationCode').error || errorMessage}
								</p>
							</FormGroup>
						</Row>
						<Row>
							<FormGroup as={Col}>
								<button className="apply-btn" type="submit">
									Submit
								</button>

								<Col className="mt-3">
									<Row className="text-center">
										<a className="link-primary" href={'#'} onClick={backToPhoneNumber}>
											Entered the wrong number?
										</a>
									</Row>
								</Col>
							</FormGroup>
						</Row>
					</Form>
				</Col>
			</Row>
		</Col>
	);
};

const LoginForm: FunctionComponent<LoginFormParams> = ({
	loading,
	authState,
	stage,
	recaptchaRef,
	phoneNumberForm,
	verificationCodeForm,
	errorMessage,
	submitPhoneNumber,
	backToPhoneNumber,
	submitVerificationCode,
}) => {
	let formElement;

	if (authState.loading || authState.authenticated || loading) {
		formElement = (
			<Col className="text-center">
				<Loader />
			</Col>
		);
	} else if (stage === LoginFormStage.PHONE_NUMBER) {
		formElement = SubmitPhoneNumberForm({ phoneNumberForm, submitPhoneNumber, errorMessage });
	} else if (stage === LoginFormStage.VERIFICATION_CODE) {
		formElement = SubmitVerificationCodeForm({
			verificationCodeForm,
			errorMessage,
			phoneNumber: phoneNumberForm.values.phoneNumber,
			backToPhoneNumber,
			submitVerificationCode,
		});
	}

	return (
		<>
			<Row className="mx-auto" style={{ maxWidth: '380px' }}>
				{formElement}
				<div>
					<div ref={recaptchaRef} />
				</div>
			</Row>
		</>
	);
};

export default LoginForm;
