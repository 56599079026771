import { useForm } from '@mantine/form';
import { ConfirmationResult, RecaptchaVerifier } from 'firebase/auth';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input/mobile';
import { useAnalytics } from '../../contexts/analytics';
import { useAuthContext } from '../../contexts/auth';
import { useFirebaseContext } from '../../contexts/firebase';
import { LoginFormParams, LoginFormStage } from './types';

const withLoginFormViewModel = (Component: FunctionComponent<LoginFormParams>) => () => {
	const { auth } = useFirebaseContext();
	const authContext = useAuthContext();
	const phoneNumberForm = useForm({
		validate: {
			phoneNumber: (value) =>
				isPossiblePhoneNumber(value) ? null : 'Unrecognised phone number format',
		},
		initialValues: { phoneNumber: '' },
	});
	const verificationCodeForm = useForm({
		validate: {
			verificationCode: (value) => (value.match(/^[0-9]{6}$/) ? null : 'Invalid verification code'),
		},
		initialValues: { verificationCode: '' },
	});
	const recaptchaRef = useRef<HTMLDivElement>(null);
	const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
	const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
	const [loading, setLoading] = useState(false);
	const [authState, setAuthState] = useState({
		loading: authContext.loading,
		authenticated: !!authContext.authUser,
	});
	const [errorMessage, setErrorMessage] = useState<string>('');
	const analytics = useAnalytics();
	useEffect(() => {
		setAuthState({ loading: authContext.loading, authenticated: !!authContext.authUser });
	}, [authContext]);

	useEffect(() => {
		if (recaptchaRef.current) {
			setRecaptchaVerifier(
				new RecaptchaVerifier(auth, recaptchaRef.current, { size: 'invisible' })
			);
		}
	}, [recaptchaRef, auth]);

	const submitPhoneNumber = (phoneNumber: string) => {
		if (recaptchaVerifier) {
			setLoading(true);
			authContext
				.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
				.then((confirmationResult) => {
					setErrorMessage('');
					setConfirmationResult(confirmationResult);
				})
				.catch((e) => {
					setErrorMessage(
						'There was a problem processing your phone number. Please check your number accurate and try again'
					);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const backToPhoneNumber = () => {
		setConfirmationResult(undefined);
		phoneNumberForm.setFieldValue('phoneNumber', '');
	};

	const submitVerificationCode = (verificationCode: string) => {
		if (verificationCode && confirmationResult) {
			setLoading(true);
			setErrorMessage('');
			confirmationResult
				.confirm(verificationCode)
				.then(async (userCredentials) => {
					setErrorMessage('');
					analytics.setUserIdentity(userCredentials.user.uid);
				})
				.catch((e) => {
					setErrorMessage('Unrecognized verification code.');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	const stage = !!confirmationResult
		? LoginFormStage.VERIFICATION_CODE
		: LoginFormStage.PHONE_NUMBER;

	const params = {
		loading,
		authState,
		stage,
		recaptchaRef,
		verificationCodeForm,
		phoneNumberForm,
		errorMessage,
		submitPhoneNumber,
		backToPhoneNumber,
		submitVerificationCode,
	};

	return <Component {...params} />;
};

export default withLoginFormViewModel;
