import { UseFormReturnType } from '@mantine/form/lib/use-form';
import type { FormEvent, Ref } from 'react';

export enum LoginFormStage {
	PHONE_NUMBER,
	VERIFICATION_CODE,
}

export interface LoginFormParams {
	loading: boolean;
	authState: { loading: boolean; authenticated: boolean };
	stage: LoginFormStage;
	recaptchaRef: Ref<HTMLDivElement>;
	phoneNumberForm: UseFormReturnType<{ phoneNumber: string }>;
	verificationCodeForm: UseFormReturnType<{ verificationCode: string }>;
	errorMessage: string;
	submitPhoneNumber: (phoneNumber: string) => void;
	backToPhoneNumber: () => void;
	submitVerificationCode: (verificationCode: string) => void;
}
